<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- account detail tab -->
    <tab-content
      title="Sepet"
      icon="feather icon-shopping-cart"
    >
      <e-commerce-checkout-step-cart @next-step="formWizardNextStep" />
    </tab-content>

    <!-- address -->
    <tab-content
      title="Adres"
      icon="feather icon-home"
    >
      <e-commerce-checkout-step-address
        :address-details="checkoutDetails.sevkiyat"
        @next-step="formWizardNextStep"
      />
    </tab-content>

    <!-- social link -->
    <tab-content
      title="Ödeme"
      icon="feather icon-credit-card"
    >
      <e-commerce-checkout-step-payment
        :payment-details="checkoutDetails.odeme"
        @next-step="formWizardNextStep"
        @finish="bitir"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import ECommerceCheckoutStepPayment from './ECommerceCheckoutStepPayment.vue'
import ECommerceCheckoutStepCart from './ECommerceCheckoutStepCart.vue'
import ECommerceCheckoutStepAddress from './ECommerceCheckoutStepAddress.vue'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'
import store from '@/store'
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    ECommerceCheckoutStepPayment,
  },
  mounted(){
    axios('https://api.ipify.org?format=json').then(response => {
       this.sIp=response.data.ip;
    });
  },
  computed:{
    ...mapGetters("app-ecommerce", ["getTradeResult"]),
    ...mapGetters("auth", ["getFirmaResult"])
  },
  methods:{
    bitir(){
      this.tamamla();
      this.$swal({
        icon: 'success',
        title: 'Tebrikler...',
        text: 'Siparişiniz Mail Olarak Gönderildi!',
        footer: '<a href="javascript:void(0)">Tercihiniz için Teşekkür Ederiz.</a>',
        //timer: 5000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    slugify (text) {
    var trMap = {
        'ş': 's', 'Ş': 'S', 'ı': 'i', 'İ': 'I', 'ç': 'c', 'Ç': 'C', 'ü': 'u',
        'Ü': 'U', 'ö': 'o', 'Ö': 'O', 'ğ': 'g', 'Ğ': 'G'
    };
    for(var key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
    }
    return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
                .replace(/\s/gi, ' ') // convert spaces to dashes
                .replace(/[-]+/gi, '-')
                .replace(/ /g, '-')
                .toLowerCase() // trim repeated dashes
              

    },
    cevir(tutar) {
        var sayi = tutar.toString()
        var sayarr = sayi.split(".");
        var eleman = 0;
        var basamak = 0;
        var basamakd = 0;
        var str = "";
        var items = [
            ["", ""],
            ["Bir", "ON"],
            ["Iki", "YIRMI"],
            ["Uc", "OTUZ"],
            ["Dort", "KIRK"],
            ["Bes", "ELLI"],
            ["Alti", "ALTMIS"],
            ["Yedi", "YETMIS"],
            ["Sekiz", "SEKSEN"],
            ["Dokuz", "DOKSAN"]
        ];
        for (eleman = 0; eleman < sayarr.length; eleman++) {
            for (basamak = 1; basamak <= sayarr[eleman].length; basamak++) {
                basamakd = 1 + (sayarr[eleman].length - basamak);
                try {
                    switch (basamakd) {
                        case 6:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0] + " YUZ";
                            break;
                        case 5:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
                            break;
                        case 4:
                            if (items[sayarr[eleman].charAt(basamak - 1)][0] != "BIR") str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0] + " BIN";
                            else str = str + " BIN";
                            break;
                        case 3:
                            if (items[sayarr[eleman].charAt(basamak - 1)][0] == "") {
                                str = str + "";

                            }
                            else if (items[sayarr[eleman].charAt(basamak - 1)][0] != "BIR") str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0] + " YUZ";
                            else str = str + " YUZ";
                            break;
                        case 2:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
                            break;
                        default:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0];
                            break;
                    }
                } catch (err) {
                    //alert(err.description);
                    //alert("eleman" + basamak);
                    break;
                }
            }
            //alert(eleman);
            if (eleman == 0) {
                str = str + " TL"
            }
            else {
                if (sayarr[1] != "00") str = str + " Krs";
            }
            //alert(str);
        }
        return str;
    },
    kontrol(){
      return new Promise((resolve,reject) => {
        resolve(true)
      })

    },
    sorgu_lFisNo(){

       return new Promise((resolve,reject) => {
        resolve(Math.floor(Math.random() * 10)); // 0 ve 9 arasında sayı üretir.
      })

    },
    sorgu_nStokID(sKodu,sAciklama){
      return new Promise((resolve,reject) => {
           resolve(Math.floor(Math.random() * 10)); // 0 ve 9 arasında sayı üretir.
      })
    },
    satir_islem(itm,index){
      return new Promise((resolve, reject) => {
        resolve(true)
      })
    },
    

        tamamla(){
      return new Promise((resolve,reject) => {
        let arr = this.getTradeResult.cartItems.slice();
        var self = this;
        self.kontrol().then(bDevam =>{
        if (bDevam==true){
        self.sorgu_lFisNo().then(lFisNo=>{
          self.tbstokfisimaster_ekle(lFisNo).then(nStokFisiID=>{
            console.log('fis eklendi'+nStokFisiID)
            arr.forEach(async (itm,index) =>{
              self.sorgu_nStokID(itm.productCode,itm.name).then(nStokID=>{
                console.log('StokID'+ nStokID);
                var lBrutFiyat =0;
                var lBrutTutar =0;
                var lDovizFiyat = 0;
                var lDovizKuru = this.getTradeResult.lDovizKuru;
                var sDovizCinsi =''
                if (itm.sDovizCinsi ==='USD') {
                  sDovizCinsi = itm.sDovizCinsi;
                  lBrutFiyat = itm.price * lDovizKuru;
                  lBrutTutar = lBrutFiyat * itm.qty
                }else {
                  sDovizCinsi='';
                  lBrutFiyat = itm.price
                  lBrutTutar = lBrutFiyat * itm.qty
                };
                self.tbstokfisidetayi_ekle(nStokFisiID,nStokID,itm.name,itm.qty,itm.nKdvOrani,lBrutFiyat,lBrutTutar,sDovizCinsi,itm.price).then(nIslemID =>{
                  console.log('satir eklendi'+ nIslemID);
                  setTimeout(function () {
                  const result = self.satir_islem(itm,index).then(satirresult=>{
                    console.log('satir bitti' + satirresult);
                    self.sSatir += "<tr class='cart_item' Width='100%'>"
                    self.sSatir += "<td class='cart-product-thumbnail'>"
                    self.sSatir += "<a href='https://tusse.com.tr/" +  self.slugify(itm.name) + '-'+ itm.id + "' class='item-quick-view' data-lightbox='ajax'><img width='64' height='64' src="+ itm.image  +" alt='" + itm.name + "'></a>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-name'>"
                    self.sSatir += "<a href='https://tusse.com.tr/" +  self.slugify(itm.name) + '-'+ itm.id + "' class='item-quick-view' data-lightbox='ajax'>" + itm.name + "</a>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-quantity'>"
                    self.sSatir += "<div class='quantity clearfix'>"
                    self.sSatir += "" + itm.qty + ""
                    self.sSatir += "</div>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-price'>"
                    self.sSatir += "<div class='price clearfix'>"
                    self.sSatir += "" + lBrutFiyat + ""
                    self.sSatir += "</div>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-subtotal'>"
                    self.sSatir += "<span class='amount'>" + lBrutTutar + "</span>"
                    self.sSatir += "</td>"
                    self.sSatir += "</tr>"
                    self.removeItemFromCart(itm);
                    console.log(result)
                    if(index == arr.length-1){
                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "<span class='amount'>" + self.checkoutDetails.sevkiyat.sNot + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += ""
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lbl_Toplam'  Text='" + self.$options.filters.toCurrency(self.tbstokfisimaster.lMalBedeli) + "'></asp:label>=========</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('toplam')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lblToplam'  Text='" + self.$options.filters.toCurrency(self.tbstokfisimaster.lMalBedeli) + "'></asp:label>" + self.$options.filters.toCurrency(self.tbstokfisimaster.lMalBedeli)  + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('kdv')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lblKdv'  Text='" + self.$options.filters.toCurrency(self.tbstokfisimaster.lKdv1)  + "'></asp:label>" + self.$options.filters.toCurrency(self.tbstokfisimaster.lKdv1)  + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('kargoucreti')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lblKargo'  Text='" + 'Kargo' + "'></asp:label>" + self.$options.filters.toCurrency(self.getTradeResult.lKargoUcreti) + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('yekun')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<span class='amount color lead'><strong><asp:label id='lblNetTutar'  Text='" + self.$options.filters.toCurrency(self.tbstokfisimaster.lNetTutar)   + "'></asp:label>" + self.$options.filters.toCurrency(self.tbstokfisimaster.lNetTutar)   + "</strong></span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sMail += self.sSatir

                      self.sMail += "</tbody>"
                      self.sMail += "</table>"
                      //'sMail += "</div>"
                      self.sMail += "<div>" + self.sFirma + "</div>"
                      self.sMail += "<p><strong>" + self.sModuller + "</strong></p>"
                      self.sMail += "<div>" + self.sOnayKodlari + "</div>"
                      self.sMail += "</p>"
                      self.sMail += "<p style=''>Her türlü sorunuzda bize <a href='mailto:destek@tusse.com.tr' style='color:#000000;'>destek@tusse.com.tr</a> adresinden ulaşabilir veya <a href='tel:+903125274888'>(+90) 312 527 4 888</a> nolu telefondan müşteri hizmetleri ile görüşebilirsiniz.</p>"
                      self.sMail += "<p style=''>Saygılarımızla,</p>"
                      self.sMail += "<p style=''>tusse ® Müşteri Hizmetleri</p>"
                      self.sMail += "</td>"
                      self.sMail += "</tr>"
                      self.sMail += "<tr>"
                      //'sMail += "<td colspan='2'><img src='https://tusse.com.tr/images/loader.gif'></td>"
                      self.sMail += "<td colspan='2'></td>"
                      self.sMail += "</tr>"
                      self.sMail += "<tr>"
                      self.sMail += "<td style='padding:10px;color:#808080;font-family:&#39;Trebuchet MS&#39;;font-size:12px;' colspan='2'>"
                      self.sMail += "<p style=''>Copyright © " +  new Date().getFullYear()  + "  tusse.com.tr Tüm hakları saklıdır.</p>"
                      self.sMail += "<p style=''><a href='https://tusse.com.tr/' style='text-decoration:none;color:#1A2640;' target='_blank'>tusse.com.tr</a></p>"
                      self.sMail += "</td>"
                      self.sMail += "</tr>"
                      self.sMail += "</tbody></table>"
                      self.sMail += "</td></tr>"
                      self.sMail += "</tbody></table>"
                      self.sMail += "<br>"
                      self.sMail += "</table></body></html>";
                      console.log('tamamlandı');
                      localStorage.setItem("sMail",self.sMail);
                      var str="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>webserviceuser@barkodyazilimevi.com</sMail>"+
                      "<sUserName>0000</sUserName>"+
                      "<sPassword>uPI9T3f5roA*CegiuSwKaxPep82asGLu</sPassword>"+
                      "<AuthenticationToken>string</AuthenticationToken>"+
                      "<sBayii>"+self.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<AuthenticationUser xmlns='http://tempuri.org/' />"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str,headers: {'Content-Type': 'text/xml','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                        //console.log(response);
                        var parseString = require('xml2js').parseString;
                        var xml = response.data
                        var AuthenticationToken =""
                        parseString(xml, function (err, result) {
                          console.log(result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0]);
                          AuthenticationToken = result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0];
                        });
                        //self.sFirma = self.sSatir+ self.sFirma;
                      
                      var str2="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>"+self.getFirmaResult.sMail+"</sMail>"+
                      "<sUserName>"+self.getFirmaResult.nFirmaID+"</sUserName>"+
                      "<sPassword>"+self.getFirmaResult.sPostaKodu+"</sPassword>"+
                      "<AuthenticationToken>"+AuthenticationToken+"</AuthenticationToken>"+
                      "<sBayii>"+self.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<mail_gonder xmlns='http://tempuri.org/'>"+
                      "<sSubject>Sipariş</sSubject>"+
                      "<nFirmaID>"+-4+"</nFirmaID>"+
                      "<sKodu>"+self.checkoutDetails.sevkiyat.sAdi+ ' ' + self.checkoutDetails.sevkiyat.sSoyadi +"</sKodu>"+
                      "<sAciklama>"+self.checkoutDetails.sevkiyat.sFirmaAdi+"</sAciklama>"+
                      "<sAdres1>"+self.checkoutDetails.sevkiyat.sAdres1+"</sAdres1>"+
                      "<sAdres2>"+self.checkoutDetails.sevkiyat.sAdres2+"</sAdres2>"+
                      "<sSemt>"+self.checkoutDetails.sevkiyat.sSemt+"</sSemt>"+
                      "<sIl>"+self.checkoutDetails.sevkiyat.sIl+"</sIl>"+
                      "<telefon>"+self.checkoutDetails.sevkiyat.sTelefon+"</telefon>"+
                      "<sEMail>"+self.checkoutDetails.sevkiyat.sMail+"</sEMail>"+
                      "<sVergiDairesi>"+self.checkoutDetails.sevkiyat.sVergiDairesi+"</sVergiDairesi>"+
                      "<sVergiNo>"+self.checkoutDetails.sevkiyat.sVergiNo+"</sVergiNo>"+
                      "<nAlisverisID>"+self.tbstokfisiodemeplani.nStokFisiID+"</nAlisverisID>"+
                      "<lFisNo>"+self.tbstokfisimaster.lFisNo+"</lFisNo>"+
                      "<sFirma><![CDATA["+  self.sSatir + self.sFirma+ "]]></sFirma>"+
                      "<sModuller><![CDATA[<strong>" + self.sModuller + "</strong>]]></sModuller>"+
                      "<sOnayKodlari><![CDATA["+ self.sOnayKodlari+"]]></sOnayKodlari>"+
                      "<sOdemeSekli>Kapıda Ödeme</sOdemeSekli>"+
                      "<sIp>"+self.sIp+"</sIp>"+
                      "</mail_gonder>"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      //console.dir(str2);
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str2,headers: {'Content-Type': 'text/xml; charset=utf-8','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                        //console.log(response); 
                      })

                        //console.log(response); 
                      
                      })
                      .catch((error) => {console.log(error)});
                      };
                      console.log('satis tamamlandi')
                      self.$router.replace('/')
                      //router.push({ name: 'blank'});
                      //self.$refs.checkoutWizard.prevTab();
                      //self.$refs.checkoutWizard.prevTab();
                      //self.loadingVisible=false;
                      //notify(self.$t('tamamlandi'), "success", 5000);
                      
                    

                    });
                  }, 500 + (index * 500));
                  })
              })
            })
            })
          });

        }
        })
      // arr.forEach(async (itm,index) =>{
        
      // setTimeout(function () {
      //  //console.log(itm.productCode);
      //  const result = self.satir_islem(itm,index).then(result=>{console.log(result)});
      //  //console.log(itm.productCode + result)
      //  }, 500 + (index * 500));
       
        
      // });
      
        // this.$validator.validateAll("cvv-form").then(result => {
        //   if (result) {
        //     // if form have no errors
        //     this.$vs.notify({
        //       title: "Success",
        //       text: "Payment received successfully",
        //       color: "success",
        //       iconPack: "feather",
        //       icon: "icon-check"
        //     });
        //   } else {
        //     this.$vs.notify({
        //       title: "Error",
        //       text: "Please enter valid details",
        //       color: "warning",
        //       iconPack: "feather",
        //       icon: "icon-alert-circle"
        //     });
        //   }
        // });

      });
    },
    tbstokfisidetayi_ekle(nStokFisiID,nStokID,sAciklama,lCikisMiktar1,nKdvOrani,lBrutFiyat,lBrutTutar,sDovizCinsi,lDovizFiyat){
      return new Promise((resolve,reject) => {
        this.tbstokfisidetayi.nStokID = nStokID;
        this.tbstokfisidetayi.nStokFisiID = nStokFisiID;
        this.tbstokfisidetayi.nFirmaID = this.tbstokfisimaster.nFirmaID;
        this.tbstokfisidetayi.dteFisTarihi = this.tbstokfisimaster.dteFisTarihi;
        this.tbstokfisidetayi.dteIslemTarihi = this.tbstokfisimaster.dteFisTarihi;
        this.tbstokfisidetayi.sFisTipi = this.tbstokfisimaster.sFisTipi;
        this.tbstokfisidetayi.lFisNo = this.tbstokfisimaster.lFisNo;
        this.tbstokfisidetayi.sStokIslem = "";
        this.tbstokfisidetayi.sHangiUygulama = this.tbstokfisimaster.sFisTipi;
        this.tbstokfisidetayi.lCikisMiktar1 = lCikisMiktar1;
        this.tbstokfisidetayi.lBrutFiyat = lBrutFiyat;
        this.tbstokfisidetayi.lBrutTutar = lBrutTutar;
        this.tbstokfisidetayi.nKdvOrani = nKdvOrani;
        this.tbstokfisidetayi.lCikisFiyat = lBrutFiyat;
        this.tbstokfisidetayi.lCikisTutar = lBrutTutar;
        this.tbstokfisidetayi.sDovizCinsi = sDovizCinsi;
        this.tbstokfisidetayi.lDovizFiyat = lDovizFiyat;
        this.tbstokfisidetayi.sAciklama = "Isk1:00.00 Isk2:00.00 Isk3:00.00 Isk4:00.00";
        this.tbstokfisidetayi.sKullaniciAdi = sAciklama;
        //console.log(this.tbstokfisidetayi);
        //mobilit.tbStokFisiDetayi.insert(this.tbstokfisidetayi).done((values,newId)=>{
          resolve(Math.floor(Math.random() * 10)); // 0 ve 9 arasında sayı üretir.
          //resolve(newId);
        //})




      })
    },
    tbstokfisimaster_ekle(lFisNo){
      return new Promise((resolve,reject) => {
        this.sMail="";
        this.sSatir="";
        this.sFirma ="";
        this.sModuller ="";
        this.sOnayKodlari ="";
        var nStokFisiID=0;
        this.tbstokfisimaster.nFirmaID=this.getFirmaResult.nFirmaID;
        this.tbstokfisimaster.lFisNo = lFisNo;
        this.tbstokfisimaster.sFisTipi ="FS";
        this.tbstokfisimaster.nGirisCikis =3;
        var currentDate = new Date();
        this.tbstokfisimaster.dteFisTarihi = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        this.tbstokfisimaster.dteValorTarihi = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        this.tbstokfisimaster.lToplamMiktar = this.getTradeResult.lToplamMiktar;
        this.tbstokfisimaster.lKdv1 = this.getTradeResult.lToplamKdv;
        this.tbstokfisimaster.lMalBedeli = this.getTradeResult.lMalBedeli;
        this.tbstokfisimaster.lMalIskontoTutari= this.getTradeResult.lMalIskontoTutari;
        this.tbstokfisimaster.lNetTutar = this.getTradeResult.lNetTutar;
        this.tbstokfisimaster.sYaziIle = this.cevir(this.tbstokfisimaster.lNetTutar);
        // this.tbstokfisimaster.sCariIslem =null;
        // this.tbstokfisimaster.sCariKdvIslem=null;
        // this.tbstokfisimaster.nKasaIslemID=null;
        // this.tbstokfisimaster.lTransferFisiID=null;
        // this.tbstokfisimaster.nTransferFirmaID=null;
        // this.tbstokfisimaster.nCariHareketID=null;
        // this.tbstokfisimaster.nCariKdvHareketID=null;
        //console.log(this.tbstokfisimaster);
        //mobilit.tbStokFisiMaster.insert(this.tbstokfisimaster).done((values,newId)=>{
          let newId = Math.floor(Math.random() * 100); // 0 ve 99 arasında sayı üretir.
          nStokFisiID = newId;
          console.log('tbstokfisimaster eklendi'+ newId);
          this.tbstokfisiaciklamasi.nStokFisiID= newId;
          this.tbstokfisiaciklamasi.sAciklama1= this.checkoutDetails.sevkiyat.sFirmaAdi;
          //console.log(this.tbstokfisiaciklamasi);
          //mobilit.tbStokFisiAciklamasi.insert(this.tbstokfisiaciklamasi).done(console.log('stokfisiaciklamasi eklendi'));
          this.tbfirmahareketi.nFirmaID = this.tbstokfisimaster.nFirmaID;
          this.tbfirmahareketi.sCariIslem = this.tbstokfisimaster.sFisTipi;
          this.tbfirmahareketi.dteIslemTarihi = this.tbstokfisimaster.dteFisTarihi;
          this.tbfirmahareketi.dteValorTarihi = this.tbstokfisimaster.dteValorTarihi;
          this.tbfirmahareketi.sEvrakNo = lFisNo.toString();
          this.tbfirmahareketi.lBorcTutar = this.tbstokfisimaster.lNetTutar;
          this.tbfirmahareketi.lAlacakTutar = 0;
          //console.log(this.tbfirmahareketi);
          //mobilit.tbFirmaHareketi.insert(this.tbfirmahareketi).done((values,newId)=>{
            console.log('tbfirmahareketi eklendi'+ newId)
            this.tbstokfisiodemeplani.nStokFisiID= nStokFisiID;
            this.tbstokfisiodemeplani.nCariHareketID= newId;
            this.tbstokfisiodemeplani.lTutari= this.tbstokfisimaster.lNetTutar;
            //console.log(this.tbstokfisiodemeplani);
            //mobilit.tbStokFisiOdemePlani.insert(this.tbstokfisiodemeplani).done(console.log('tbstokfisiodemeplani eklendi'));
            this.tbstokfisipesinadre.nStokFisiID= nStokFisiID;
            this.tbstokfisipesinadre.sAciklama = this.checkoutDetails.sevkiyat.sFirmaAdi;
            this.tbstokfisipesinadre.sAdres1 = this.checkoutDetails.sevkiyat.sAdres1;
            this.tbstokfisipesinadre.sAdres2 = this.checkoutDetails.sevkiyat.sAdres2;
            this.tbstokfisipesinadre.sSemt = this.checkoutDetails.sevkiyat.sSemt;
            this.tbstokfisipesinadre.sIl = this.checkoutDetails.sevkiyat.sIl;
            this.tbstokfisipesinadre.sVergiDairesi= this.checkoutDetails.sevkiyat.sVergiDairesi;
            this.tbstokfisipesinadre.sVergiNo = this.checkoutDetails.sevkiyat.sVergiNo;
            this.tbstokfisipesinadre.sPostaKodu = this.checkoutDetails.sevkiyat.sMail;
            this.tbstokfisipesinadre.sTelefon1 = this.checkoutDetails.sevkiyat.sTelefon;
            //console.log(this.tbstokfisipesinadre);
            //mobilit.tbStokFisiPesinAdres.insert(this.tbstokfisipesinadre).done(
              console.log('tbstokfisipesinadres eklendi'),
            this.sMail ="<html><head><title>tusse.com.tr Musteri Hizmetleri</title></head><body>",
            this.sMail += "<div align='center'>",
            this.sMail += "<table style='background-color:#425967;padding:10px;width:620px;text-align:left;' width='620' cellpadding='0' cellspacing='0'>",
            this.sMail += "<tbody><tr><td>",
            this.sMail += "<table width='100%' style='background-color:#FFFFFF;' cellpadding='0' cellspacing='0'>",
            this.sMail += "<tbody><tr>",
            this.sMail += "<td style='padding:10px;'><a href='https://www.tusse.com.tr/' target='_blank'><img src='https://www.tusse.com.tr/logo.png' border='0'></a></td>",
            this.sMail += "<td style='color:#1A2640;font-family:&#39;Trebuchet MS&#39;;font-size:14px;' align='right'>+90 312 527 4 888<span style='color:#A5B9C5;font-size:24px;'>|</span> <a href='https://www.tusse.com.tr' style='text-decoration:none;color:#1A2640;' target='_blank'>www.tusse.com.tr</a>&nbsp;&nbsp;&nbsp;</td>",
            this.sMail += "</tr>",
            this.sMail += "<tr>",
            //'sMail += "<td colspan='2'><img src='https://www.tusse.com.tr/images/loader.gif'></td>"
            this.sMail += "<td colspan='2'></td>",
            this.sMail += "</tr>",
            this.sMail += "<tr>",
            this.sMail += "<td style='padding:10px;font-size:12px;font-family:&#39;Arial&#39;;' colspan='2'>",
            this.sMail += "<p style=''>"+this.$t('sayin')+"<strong>" + this.checkoutDetails.sevkiyat.sFirmaAdi+ "</strong>,</p>",
            this.sMail += "<p style=''>" + new Date().toLocaleString() + " tarihinde, " + window.location.host + " IP adresinden Sipariş Talebinde bulundunuz. Sipariş içeriğiniz aşağıda sunulmuştur: <br><br>",

            this.sMail += "<h4>"+this.$t('siparis')+"</h4>",

            this.sMail += "<table class='table cart' Width='100%'>",
            this.sMail += "<tbody>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td><strong>"+this.$t('sayin')+"</strong></td>",
            this.sMail += "<td>" + this.getFirmaResult.sKodu + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td><strong>"+ this.$t('aciklama') + "</strong></td>",
            this.sMail += "<td> " + this.checkoutDetails.sevkiyat.sFirmaAdi+ "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>" + this.$t('kayitno') + "</strong></td>",
            this.sMail += "<td>" + nStokFisiID + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td><strong>"+ this.$t('adres') +"</strong></td>",
            this.sMail += "<td>" + this.checkoutDetails.sevkiyat.sAdres1 + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>"+ this.$t('tarih') +"</strong></td>",
            this.sMail += "<td>" + this.tbstokfisimaster.dteFisTarihi.toLocaleString() + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>" + this.checkoutDetails.sevkiyat.sAdres2 + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>"+ this.$t('fisno') +"</strong></td>",
            this.sMail += "<td>" + this.tbstokfisimaster.lFisNo + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>" + this.checkoutDetails.sevkiyat.sSemt + " | " + this.checkoutDetails.sevkiyat.sIl + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>"+this.$t('odeme')+"</strong></td>",
            
            this.sMail += "<td>" + this.$t('kapidaodeme') + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>" + this.checkoutDetails.sevkiyat.sTelefon + "|" + this.checkoutDetails.sevkiyat.sMail + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>"+this.$t('vergidairesi')+": " + this.checkoutDetails.sevkiyat.sVergiDairesi + "  " + this.$t('vkn') +": " + this.checkoutDetails.sevkiyat.sVergiNo + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "</tr>",

            this.sMail += "</tbody>",
            this.sMail += "</table>",
            this.sMail += "<p></p>",
            this.sMail += "<p></p>",

            this.sSatir += "<table class='table cart' Width='100%'>",
            this.sSatir += "<thead>",
            this.sSatir += "<tr>",
            this.sSatir += "<th class='cart-product-thumbnail'>&nbsp;</th>",
            this.sSatir += "<th class='cart-product-name'>"+this.$t('aciklama')+"</th>",
            this.sSatir += "<th class='cart-product-quantity'>"+this.$t('miktar')+"</th>",
            this.sSatir += "<th class='cart-product-price'>"+this.$t('fiyat')+"</th>",
            this.sSatir += "<th class='cart-product-subtotal'>"+this.$t('tutar')+"</th>",
            this.sSatir += "</tr>",
            this.sSatir += "</thead>",
            this.sSatir += "<tbody>",

            //this.sMail += sSatir,

            this.sFirma = "<p>"+this.$t('sevkiyatadresi')+";</p>",
            this.sFirma += "<p>",
            this.sFirma += "<strong>"+this.$t('kayitno')+":</strong> " + this.checkoutDetails.sevkiyat.nFirmaID + "<br>",
            this.sFirma += "<strong>"+this.$t('aciklama')+":</strong> " + this.checkoutDetails.sevkiyat.sFirmaAdi + "<br>",
            this.sFirma += "<strong>"+this.$t('adsoyad')+":</strong> " + this.checkoutDetails.sevkiyat.sAdi + ' ' + this.checkoutDetails.sevkiyat.sSoyadi + "<br>",
            this.sFirma += "<strong>"+this.$t('adres')+":</strong> " + this.checkoutDetails.sevkiyat.sAdres1 + "<br>",
            this.sFirma += "<strong>"+this.$t('adres')+":</strong> " + this.checkoutDetails.sevkiyat.sAdres2 + "<br>",
            this.sFirma += "<strong>"+this.$t('il')+":</strong> " + this.checkoutDetails.sevkiyat.sIl + "<br>",
            this.sFirma += "<strong>"+this.$t('sehir')+":</strong> " + this.checkoutDetails.sevkiyat.sSemt+ "<br>",
            this.sFirma += "<strong>"+this.$t('vergidairesi')+":</strong> " + this.checkoutDetails.sevkiyat.sVergiDairesi + "<br>",
            this.sFirma += "<strong>"+this.$t('vkn')+":</strong> " + this.checkoutDetails.sevkiyat.sVergiNo + "<br>",
            this.sFirma += "<strong>"+this.$t('email')+":</strong> " + this.checkoutDetails.sevkiyat.sMail + "<br>",
            this.sFirma += "<strong>"+this.$t('telefon')+":</strong> " + this.checkoutDetails.sevkiyat.sTelefon + "<br>",
            this.sFirma += "<strong>"+this.$t('yetkili')+":</strong> " + this.checkoutDetails.sevkiyat.sAdi  + ' ' + this.checkoutDetails.sevkiyat.sSoyadi +"<br>",
            this.sFirma += "<strong>"+this.$t('sektor')+":</strong> " + this.checkoutDetails.sevkiyat.sSektor + "<br>",
            this.sFirma += "<strong>"+this.$t('notlar')+":</strong> " + this.checkoutDetails.sevkiyat.sNot + "<br>",
            this.sFirma += "</p>",
              resolve(nStokFisiID)
              //);


          //})
          
        //})

      })


    },
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      window.scrollTo(0, 200)
      refFormWizard.value.nextTab()
    }

    const products = ref([])
    const { removeProductFromCart } = useEcommerce()
    const removeItemFromCart = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
          //store.commit('app-ecommerce/toplamhesapla', products.value)
        })
    }

      const sMail=ref("")
      const sSatir=ref("")
      const sFirma= ref("")
      const sModuller= ref("")
      const sOnayKodlari= ref("")
      const sIp= ref('0.0.0.0')
    

    

    // ? This is just dummy details
    const checkoutDetails = ref({
      sevkiyat: {
        nFirmaID:0,
        sFirmaKodu:'',
        sFirmaAdi: '',
        sAdi:'',
        sSoyadi:'',
        sAdres1: '',
        sAdres2: '',
        sSemt: '',
        sIl:'İSTANBUL',
        sVergiDairesi: '',
        sVergiNo:'',
        sMail:'',
        sTelefon:'',
        sNot:'',
        sYetkili:'',
        sSektor:'',
        
      },
      odeme: {
        sOdemeSekli: 'N',
      },
    })

    return {
      refFormWizard,
      formWizardNextStep,
      removeItemFromCart,

      // Dummy Details
      checkoutDetails,

      sMail,
      sSatir,
      sFirma,
      sModuller,
      sOnayKodlari,
      sIp,
      tbstokfisimaster:{sFisTipi:"FS",dteFisTarihi: new Date(),nGirisCikis:3,lFisNo:0,nFirmaID:0,sDepo:"D001",dteValorTarihi: new Date(),bPesinmi:false,bListelendimi:false,bHizmetFaturasimi:false,lToplamMiktar:0.000,lMalBedeli:0.00,lMalIskontoTutari:0.00,nDipIskontoYuzdesi1:0.00,lDipIskontoTutari1:0.00,nDipIskontoYuzdesi2:0.00,lDipIskontoTutari2:0.00,lDipIskontoTutari3:0.00,lEkmaliyet1:0.00,lEkmaliyet2:0.00,lEkmaliyet3:0.00,nKdvOrani1:0,lKdvMatrahi1:0,lKdv1:0,nKdvOrani2:0,lKdvMatrahi2:0,lKdv2:0,nKdvOrani3:0,lKdvMatrahi3:0,lKdv3:0,nKdvOrani4:0,lKdvMatrahi4:0,lKdv4:0,nKdvOrani5:0,lKdvMatrahi5:0,lKdv5:0,lNetTutar:0.00,nTevkifatKdvOrani:0,lTevkifatKdvMatrahi:0,lTevkifatKdv:0,sCariIslem:"FS",nCariHareketID:0,sHareketTipi:"",bMuhasebeyeIslendimi:false,bFisTamamlandimi:false,lTransferFisiID:0,sTransferDepo:"",bFaturayaDonustumu:false,sKullaniciAdi:"mobil",dteKayitTarihi:new Date(),sYaziIle:"",nOTVOrani1:0,lOTVMatrahi1:0,lOTV1:0,nOTVOrani2:0,lOTVMatrahi2:0,lOTV2:0,bKilitli:false,bEfatura:false,sEfaturaTipi:"",sEfaturaGuid:"",nEfaturaDurum:0},
      tbstokfisiaciklamasi:{nStokFisiID:0,sAciklama1:"",sAciklama2:"",sAciklama3:"",sAciklama4:"",sAciklama5:""},
      tbstokfisiodemeplani:{sCariIslem:"FS",nStokFisiID:0,dteVadeTarihi: new Date(),lTutari:0.00,nCariHareketID:0,sDovizCinsi:"",lDovizMiktari:0.00,lDovizKuru:0.00},
      tbfirmahareketi:{sCariIslem:"FS",sDovizCinsi1:"",sDovizCinsi2:"",nFirmaID:0,sHangiUygulama:"FS",sHareketTipi:"",dteIslemTarihi: new Date(),dteValorTarihi: new Date(),sAciklama:"",lBorcTutar:0.00,lAlacakTutar:0.00,sEvrakNo:"",lVadeFarki:0,sKullaniciAdi:"mobil",dteKayitTarihi:new Date(),lDovizMiktari1:0.00,lDovizKuru1:0.00,lDovizMiktari2:0.00,lDovizKuru2:0.00,sSonKullaniciAdi:"mobil",dteSonUpdateTarihi:new Date(),sAciklama1:"",bVar:false},
      tbstokfisipesinadre:{nStokFisiID:0,sAciklama:"",sAdres1:"",sAdres2:"",sSemt:"",sIl:"",sUlke:"",sPostaKodu:"",sVergiDairesi:"",sVergiNo:"",sSubeMagaza:"",sTelefon1:"",sTelefon2:"",sTelefon3:"",sFaks:"",sMail:"",sAracSasiNo:"",sAracMotorNo:"",sAracMarka:"",sAracModel:"",sAracRenk:"",sSaticiRumuzu:""},
      tbstokfisidetayi:{nStokID:0,dteIslemTarihi:new Date(),nMusteriID:0,nFirmaID:0,sFisTipi:"FS",dteFisTarihi: new Date(),lFisNo:0,nGirisCikis:3,sDepo:"D001",lReyonFisNo:0,sStokIslem:"FS",sKasiyerRumuzu:"",sSaticiRumuzu:"",sOdemeKodu:"",dteIrsaliyeTarihi:new Date(),lIrsaliyeNo:0,lGirisMiktar1:0,lGirisMiktar2:0,lGirisFiyat:0.00,lGirisTutar:0.00,lCikisMiktar1:0,lCikisMiktar2:0,lCikisFiyat:0.00,lCikisTutar:0.00,sFiyatTipi:"1",lBrutFiyat:0.00,lBrutTutar:0.00,lMaliyetFiyat:0.00,lMaliyetTutar:0.00,lIlaveMaliyetTutar:0.00,nIskontoYuzdesi:0,lIskontoTutari:0.00,sDovizCinsi:"",lDovizFiyat:0.00,nReceteNo:0,nKdvOrani:0,nHesapID:0,sAciklama:"",sHareketTipi:"",bMuhasebeyeIslendimi:false,sKullaniciAdi:"mobil",dteKayitTarihi: new Date(),sDovizCinsi1:"",lDovizMiktari1:0,lDovizKuru1:0,sDovizCinsi2:"",lDovizMiktari2:0,lDovizKuru2:0,nOTVOrani:0,nStokFisiID:0,sMasrafYontemi:"",sHangiUygulama:"FS",nEkSaha1:0,nEkSaha2:0,bEkSoru1:false,bEkSoru2:false,sSonKullaniciAdi:"mobil",dteSonUpdateTarihi: new Date(),sBirimCinsi:"",lBirimMiktar:1,nPrim:0,lPrimTutari:0},
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
