<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <e-commerce-checkout-step-cart-products />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>

        <!-- <label class="section-label mb-1">Options</label>
        <b-input-group class="input-group-merge coupons">
          <b-form-input :placeholder="$t('kuponlar')" />
          <b-input-group-append is-text>
            <span
              id="input-coupons"
              class="input-group-text text-primary cursor-pointer"
            >{{$t('uygula')}}</span>
          </b-input-group-append>
        </b-input-group>
        <hr> -->
        <div class="price-details">
          <h6 class="price-title">
            {{$t('toplamlar')}}
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                {{$t('toplam')}}
              </div>
              <div class="detail-amt">
                {{this.getTradeResult.lMalBedeli |toCurrency}}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                {{$t('iskonto')}}
              </div>
              <div class="detail-amt discount-amt text-success">
                {{this.getTradeResult.lMalIskontoTutari|toCurrency}}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                {{$t('kdv')}}
              </div>
              <div class="detail-amt">
                {{this.getTradeResult.lToplamKdv|toCurrency}}
              </div>
            </li>
            <!-- <li class="price-detail">
              <div class="detail-title">
                EMI Eligibility
              </div>
              <a
                href="javascript:void(0)"
                class="detail-amt text-primary"
              >Details</a>
            </li> -->
            <li class="price-detail">
              <div class="detail-title">
                {{$t('kargoucreti')}}
              </div>
              <div class="detail-amt">
                {{this.getTradeResult.lKargoUcreti|toCurrency}}
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                {{$t('yekun')}}
              </div>
              <div class="detail-amt font-weight-bolder">
                {{this.getTradeResult.lNetTutar|toCurrency}}
              </div>
            </li>
          </ul>
          <b-button v-if="getTradeResult.lNetTutar>0"
            variant="primary"
            block
            @click="$emit('next-step')"
          >
            {{$t('tamamla')}}
          </b-button>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

import { mapGetters } from "vuex";

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  mounted() {
    this.fetchItems()
    
  },
  computed: {
    totalAmount() {
      let total = 0
      this.items.forEach(i => { total += i.price * i.qty })
      return total
    },
    ...mapGetters("app-ecommerce", ["getTradeResult"]),
  },
  methods: {
    fetchItems() {
      this.$store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          this.items = response.data.products
        })
    },
    updateItemQuantity(qty, productId) {
      this.$store.dispatch('app-ecommerce/updateItemQuantity', { productId, qty })
        .then(() => {
          store.commit('app-ecommerce/toplamhesapla', this.items)
          this.fetchItems()
          // const itemIndex = this.items.findIndex(p => p.id === productId)
          // console.log(event)
          // console.log(itemIndex)
        })
    },
    removeItemFromCart(productId) {
      this.$store.dispatch('app-ecommerce/removeProductFromCart', { productId })
        .then(() => {
          this.fetchItems()
          const itemIndex = this.items.findIndex(p => p.id === productId)
          this.items.splice(itemIndex, 1)

          // Update count in cart items state
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
          this.$store.commit('app-ecommerce/toplamhesapla', this.items)
        })
    },
  },
}
</script>
