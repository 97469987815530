<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Ödeme Seçenekleri</b-card-title>
        <b-card-text class="text-muted mt-25">
          Doğru ödeme seçeneğine tıkladığınızdan emin olun
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <h6 class="card-holder-name my-75">
          Tusse
        </h6>

        <!-- Radios -->
        <b-form-group>
          <!-- <b-form-radio
            name="payment-method"
            value="john-doe-debit-card"
            checked="john-doe-debit-card"
          >
            US Unlocked Debit Card 12XX XXXX XXXX 0000
          </b-form-radio>
          <div class="d-flex flex-wrap align-items-center justify-content-start my-1">
            <label
              for="cvv"
              class="text-nowrap mr-1 mb-1"
            >
              Enter CVV:
            </label>
            <b-form-input
              id="cvv"
              v-model="paymentDetails.cvv"
              class="mr-1 mb-1"
              trim
            />
            <b-button
              variant="primary"
              class="mb-1"
            >
              Continue
            </b-button>
          </div>

          <hr class="mb-2 mt-1"> -->

          <!-- <b-form-radio
            name="payment-method"
            value="debit-atm-credit-card"
          >
            Credit / Debit / ATM Card
          </b-form-radio>
          <b-form-radio
            name="payment-method"
            class="mt-1"
            value="net-banking"
          >
            Net Banking
          </b-form-radio>
          <b-form-radio
            name="payment-method"
            class="mt-1"
            value="emi"
          >
            EMI (Easy Installment)
          </b-form-radio> -->
          <b-form-radio
            name="payment-method"
            class="mt-1"
            v-model="paymentDetails.sOdemeSekli"
            value="N"
          >
            Kapıda Ödeme
          </b-form-radio>
        </b-form-group>

        <!-- <hr class="my-2">

        <p class="cursor-pointer">
          <feather-icon
            icon="PlusCircleIcon"
            size="21"
            class="mr-75"
          />
          <span class="align-middle">Add Gift Card</span>
        </p> -->
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Ödeme Detayları">

        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Toplam
            </div>
            <div class="detail-amt">
              <strong>{{this.getTradeResult.lMalBedeli | toCurrency}}</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Kdv
            </div>
            <div class="detail-amt">
              <strong>{{this.getTradeResult.lToplamKdv|toCurrency}}</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Teslimat Ücreti
            </div>
            <div class="detail-amt discount-amt text-success">
              {{this.getTradeResult.lKargoUcreti|toCurrency}}
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Ödenecek Tutar
            </div>
            <div class="detail-amt font-weight-bolder">
              {{this.getTradeResult.lNetTutar|toCurrency}}
            </div>
          </li>
        </ul>

        <!-- Submit Button -->
        <b-row>
            
              <b-button
                variant="primary"
                type="submit"
                block
                @click="showMsgBoxTwo"
              >
                Bitir
              </b-button>
            
          </b-row>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormGroup, BFormRadio, BFormInput, BButton,BRow
} from 'bootstrap-vue'

import { mapGetters } from "vuex";

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    BRow,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      boxOne: '',
      boxTwo: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  mounted() {
    this.fetchItems()
  },
  activated(){
    this.fetchItems()
  },
  computed: {
    totalAmount() {
      let total = 0
      this.items.forEach(i => { total += i.price * i.qty })
      return total
    },
    ...mapGetters("app-ecommerce", ["getTradeResult"]),
  },
  methods: {
    fetchItems() {
      this.$store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          this.items = response.data.products
        })
    },
    updateItemQuantity(qty, productId) {
      this.$store.dispatch('app-ecommerce/updateItemQuantity', { productId, qty })
        .then(() => {
          // const itemIndex = this.items.findIndex(p => p.id === productId)
          // console.log(event)
          // console.log(itemIndex)
        })
    },
    removeItemFromCart(productId) {
      this.$store.dispatch('app-ecommerce/removeProductFromCart', { productId })
        .then(() => {
          const itemIndex = this.items.findIndex(p => p.id === productId)
          this.items.splice(itemIndex, 1)

          // Update count in cart items state
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
        })
    },
    showMsgBoxTwo() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Siparişi Onaylamak İstediğinize Emin misiniz?', {
          title: 'Dikkat',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Evet',
          cancelTitle: 'Hayır',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
          if (value){
            this.$emit('finish')
            //this.withFooter()
          }
          
        })
    },
    withFooter() {
      this.$swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Birşeyler Yanlış Gitti!',
        footer: '<a href="javascript:void(0)">Neden bu sorunu yaşıyorum?</a>',
        timer: 5000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
