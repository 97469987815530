<template>

  <div class="checkout-items" v-if="getTradeResult.cartItems.length">
    <b-card
      v-for="product in getTradeResult.cartItems"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link :to="{ name: 'apps-e-commerce-product-details', params: { slug: slugify(product.name) + '-' + product.id} }">
          <b-img
            :src="product.image"
            :alt="`${product.name}-${product.id}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body" :to="{ name: 'apps-e-commerce-product-details', params: { slug: slugify(product.name) + '-' + product.id} }">
              {{ product.name }}
            </b-link>
          </h6>
          <span class="item-company">By <b-link class="company-name">{{ product.brand }}</b-link></span>
          <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{'ml-25': star-1}"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                />
              </li>
            </ul>
          </div>
        </div>
        <span class="text-success mb-1">{{$t('mevcut')}}</span>
        <div class="item-quantity">
          <span class="quantity-title">{{$t('miktar')}}:</span>
          <b-form-spinbutton
            v-model="product.qty"
            size="sm"
            class="ml-75"
            inline
            @input="updateItemQuantityClick(product)"
          />
        </div>
        <span class="delivery-date text-muted">{{$t('teslimat')}} {{ formatDate(product.shippingDate, { month: 'short', day: 'numeric', weekday: 'short' }) }}</span>
        <!-- <span class="text-success">{{ product.discountPercentage }}% off {{ product.offers }} offers Available</span> -->
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              {{ product.price }}
            </h4>
            <p
              v-if="product.hasFreeShipping"
              class="card-text shipping"
            >
              <b-badge
                pill
                variant="light-success"
                v-if="getTradeResult.lKargoUcreti==0"
              >
                Ücretsiz Teslimat
              </b-badge>
            </p>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartClick(product)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>{{$t('kaldir')}}</span>
        </b-button>
        <b-button
          variant="primary"
          class="btn-cart move-cart"
          @click="toggleProductInWishlist(product)"
        >
          <feather-icon
            icon="HeartIcon"
            class="mr-50"
            :class="{'fill-current': product.isInWishlist}"
          />
          <span class="text-nowrap">{{$t('favori')}}</span>
        </b-button>
      </div>
    </b-card>
  </div>
  <b-card title="Sepetinizde Ürün Bulunamadı..!" v-else><b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="$router.push('/')">Mağazaya Git</b-button></b-card>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'

import { mapGetters } from "vuex";

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  computed:{
    ...mapGetters("app-ecommerce", ["getTradeResult"]),
  },
  methods: {


    slugify (text) {
      var trMap = {
        'ş': 's', 'Ş': 'S', 'ı': 'i', 'İ': 'I', 'ç': 'c', 'Ç': 'C', 'ü': 'u',
        'Ü': 'U', 'ö': 'o', 'Ö': 'O', 'ğ': 'g', 'Ğ': 'G'
      };
      for(var key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
      }
      return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
                .replace(/\s/gi, ' ') // convert spaces to dashes
                .replace(/[-]+/gi, '-')
                .replace(/ /g, '-')
                .toLowerCase() // trim repeated dashes
    }

  },
  setup() {
    const products = ref([])

    const { toggleProductInWishlist } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
          store.commit('app-ecommerce/toplamhesapla', products.value)
        })
    }
    const { updateItemQuantity } = useEcommerce()
    const updateItemQuantityClick = product => {
      updateItemQuantity(product.id, product.qty)
        .then(() => { store.commit('app-ecommerce/toplamhesapla', product) })
    }

    const fetchCartProducts = () => {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          products.value = response.data.products
        })
    }
    fetchCartProducts()

    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,
      updateItemQuantityClick,

      // Filter
      formatDate,
    }
  },
}
</script>

<style>

</style>
