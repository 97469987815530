<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >

      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Fatura Adresi</b-card-title>
          <!-- <b-card-text class="text-muted mt-25">
            Be sure to check "Deliver to this address" when you have finished
          </b-card-text> -->
        </b-card-header>
        <b-card-body>
          <b-row>

            
            <!-- Firma Adı -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Firma Adı"
                
              >
                <b-form-group
                  label="Firma Adı"
                  label-for="firma-adi"
                  class="mb-2"
                >
                  <b-form-input
                    id="firma-adi"
                    v-model="addressDetails.sFirmaAdi"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Ad -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="ad"
                rules="required"
              >
                <b-form-group
                  label="Ad"
                  label-for="Ad"
                  class="mb-2"
                >
                  <b-form-input
                    id="sAdi"
                    v-model="addressDetails.sAdi"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- soyadi -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="soyadi"
                rules="required"
              >
                <b-form-group
                  label="Soyad"
                  label-for="soyad"
                  class="mb-2"
                >
                  <b-form-input
                    id="sSoyadi"
                    v-model="addressDetails.sSoyadi"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            

            <!-- sAdres1 -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Adres1"
                rules="required"
              >
                <b-form-group
                  label="Adres"
                  label-for="sAdres1"
                  class="mb-2"
                >
                  <b-form-input
                    id="sAdres1"
                    v-model="addressDetails.sAdres1"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Landmark -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="sAdres2"
                
              >
                <b-form-group
                  label-for="sAdres2"
                  class="mb-2"
                >
                  <b-form-input
                    id="sAdres2"
                    v-model="addressDetails.sAdres2"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- City -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Town/city"
                rules="required"
              >
                <b-form-group
                  label="Semt"
                  label-for="city"
                  class="mb-2"
                >
                  <b-form-input
                    id="city"
                    v-model="addressDetails.sSemt"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- il -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="il"
                rules="required"
              >

                <b-form-group
                  label="il"
                  label-for="il"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="addressDetails.sIl"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['ADANA','ADIYAMAN','AFYONKARAHİSAR','AĞRI','AMASYA','ANKARA','ANTALYA','ARTVİN','AYDIN','BALIKESİR','BİLECİK','BİNGÖL','BİTLİS','BOLU','BURDUR','BURSA','ÇANAKKALE','ÇANKIRI','ÇORUM','DENİZLİ','DİYARBAKIR','EDİRNE','ELAZIĞ','ERZİNCAN','ERZURUM','ESKİŞEHİR','GAZİANTEP','GİRESUN','GÜMÜŞHANE','HAKKARİ','HATAY','ISPARTA','MERSİN','İSTANBUL','İZMİR','KARS','KASTAMONU','KAYSERİ','KIRKLARELİ','KIRŞEHİR','KOCAELİ','KONYA','KÜTAHYA','MALATYA','MANİSA','KAHRAMANMARAŞ','MARDİN','MUĞLA','MUŞ','NEVŞEHİR','NİĞDE','ORDU','RİZE','SAKARYA','SAMSUN','SİİRT','SİNOP','SİVAS','TEKİRDAĞ','TOKAT','TRABZON','TUNCELİ','ŞANLIURFA','UŞAK','VAN','YOZGAT','ZONGULDAK','AKSARAY','BAYBURT','KARAMAN','KIRIKKALE','BATMAN','ŞIRNAK','BARTIN','ARDAHAN','IĞDIR','YALOVA','KARABüK','KİLİS','OSMANİYE','DÜZCE']"
                    input-id="address-type"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- sVergiDairesi -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="VergiDairesi"
                
              >
                <b-form-group
                  label="Vergi Dairesi"
                  label-for="sVergiDairesi"
                  class="mb-2"
                >
                  <b-form-input
                    id="sVergiDairesi"
                    v-model="addressDetails.sVergiDairesi"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- VergiNo -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="VergiNo"
                
              >
                <b-form-group
                  label="Vergi No"
                  label-for="sVergiNo"
                  class="mb-2"
                >
                  <b-form-input
                    id="sVergiNo"
                    v-model="addressDetails.sVergiNo"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- email -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Mail"
                rules="required"
              >
                <b-form-group
                  label="Mail"
                  label-for="mail"
                >
                  <b-form-input
                    id="mail"
                    v-model="addressDetails.sMail"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Mobile Number -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Telefon"
                rules="required|integer"
              >
                <b-form-group
                  label="Telefon"
                  label-for="telefon"
                >
                  <b-form-input
                    id="telefon"
                    v-model="addressDetails.sTelefon"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- not -->
            <b-col
              cols="12"
              md="12"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Notlar"
                
              >
                <b-form-group
                  label="Notlar"
                  label-for="sNot"
                >
                <b-form-textarea
                    id="sNotlar"
                    v-model="addressDetails.sNot"
                    :state="getValidationState(validationContext)"
                    placeholder="Notlar"
                    rows="4"
                    max-rows="6"
                    trim
                ></b-form-textarea>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            

            

            <!-- Submit Button -->
            <b-col cols="12" md="12" class="mb-2">
              <b-button
                variant="primary"
                type="submit"
                block
              >
                Devam
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Right Col -->
      <!-- <div class="customer-card">
        <b-card title="Tusse">
          <b-card-text class="mb-0">
            9447 Glen Eagles Drive
          </b-card-text>
          <b-card-text>Lewis Center, OH 43035</b-card-text>
          <b-card-text>UTC-5: Eastern Standard Time (EST)</b-card-text>
          <b-card-text>202-555-0140</b-card-text>
          <b-button
            variant="primary"
            block
            @click="$emit('next-step')"
          >
            Deliver To This Address
          </b-button>
        </b-card>
      </div> -->
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BFormInvalidFeedback,BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const onSubmit = () => {
      emit('next-step')
    }

    return {

      // Form
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
